/*Photo Credits*/
.geo-container {
  min-width: 20%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 14px;
  cursor: pointer;
}

.image-location {
  min-width: 200px;
  transition: transform 0.5s linear;
}

.image-credit {
  position: absolute;
  display: flex;
  align-items: center;
  font-size: 13px;
  top: 5px;
  min-width: 200px;
  transform: translateY(-100%);
  transition: transform 0.7s linear;
  visibility: hidden;
  margin-top: 10px;
}

.image-credit a {
  opacity: 0.7;
}

.image-credit a,
.image-credit a:hover,
.image-credit a:focus,
.image-credit a:active {
  text-decoration: none;
  color: #fff;
}

.image-credit a:hover {
  opacity: 1;
}

.image-like {
  color: #fff;
  font-size: 0.9rem;
  padding: 0 4px;
}

.geo-container:hover > .image-location {
  transform: translateY(-25%);
}

.geo-container:hover > .image-credit {
  transform: translateY(2px);
  visibility: visible;
}
