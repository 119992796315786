#time {
  font-size: 950%;
  font-weight: 600;
  line-height: 1;
}

.greeting-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.greeting-container h1,
h2 {
  letter-spacing: 2;
}

.greeting-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.greeting-container input[type='text'] {
  background: none;
  color: #fff;
  font-size: 2rem;
  font-weight: 800;
  outline: none;
  border: 0;
}

.name::placeholder,
.focus::placeholder {
  font-family: 'Poppins', 'sans-serif';
  font-size: 2rem;
  color: #fff;
  opacity: 0.6;
}

.greeting-limit {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.greeting,
.name {
  font-size: 2.75rem;
}

.name {
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  outline: 0;
  border: 0;
  padding-left: 10px;
}

.punctuation {
  display: inline-block;
  font-size: 3rem;
  visibility: hidden;
}

.punctuation.show {
  visibility: visible;
}

.focus-q,
.focus,
.focus-show-line {
  font-size: 2rem;
}

.focus-q {
  margin-top: 20px;
}

.focus {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  outline: 0;
  border: 0;
  width: 100%;
  transition: all 0.5s ease;
  font-weight: 700;
  padding-bottom: 10px;
}

.focus.show-line {
  border-bottom: 2px solid #fff;
}
