/*Quote*/
.quote-container {
  font-size: 14px;
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  transition: transform 0.5s linear;
  cursor: pointer;
  min-width: 60%;
}

#quote {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  transition: transform 0.5s linear;
  white-space: nowrap;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}

#author {
  position: absolute;
  top: 13px;
  min-width: 200px;
  left: calc(50% - (200px / 2));
  transform: translateY(-100%);
  opacity: 0;
  transition: transform 0.7s linear;
}

.quote-container:hover > #quote {
  transform: translateY(-25%);
  opacity: 1;
}

.quote-container:hover > #author {
  transform: translateY(2px);
  opacity: 1;
}
