.search-wrap {
  max-width: 330px;
  min-width: 330px;
}

.search-container {
  color: #fff;
  width: 80%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.search-form-container {
  display: flex;
  width: 100%;
  min-width: 100%;
}

.search-title {
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-title p {
  margin-right: 10px;
}

.search-title .search-icon {
  padding-right: 6px;
}

.search-title,
.search-icon {
  cursor: pointer;
}

/*SEARCH FORM*/
.search-form {
  display: flex;
  border: none;
  border-bottom: 1.5px solid rgba(228, 222, 222, 0.7);
  outline: none;
  background: transparent;
  color: #fff;
  opacity: 0;
  width: 100%;
  transition: opacity 0.5s ease;
}

.search-form.show {
  opacity: 1;
}

.search-form i {
  padding-right: 6px;
}

.search-input {
  color: #fff;
  font-weight: 500;
  padding: 8px;
  border: none;
  outline: none;
  background: none;
  width: 85%;
}

.search-submit-btn {
  color: #fff;
  height: 20px;
  width: 20px;
  background: none;
  border: none;
  outline: 0;
  cursor: pointer;
}

.search-arrow {
  display: flex;
  align-items: flex-end;
  padding-bottom: 2px;
}

.arrow {
  font-size: 10px;
  color: #fff;
  padding: 2px;
  opacity: 0.8;
  cursor: pointer;
}

.arrow:hover {
  opacity: 1;
}

/*.down {
  transform: rotate(45deg);
}*/

/*SEARCH LINKS CARD*/
.search-links-card {
  width: 160px;
  max-width: 160px;
  max-height: 140px;
  align-self: flex-end;
  margin-top: 15px;
  padding: 5px 12px;
  font-size: 14px;
  background: #f3f0f0;
  color: #000;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.search-links-list p {
  padding: 3px 16px;
  color: #635e5e;
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
}

.search-links-card .search-links-list {
  min-width: 120px;
  min-height: 120px;
  border-radius: 5px;
  padding: 7px 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.search-links-list li {
  display: flex;
  align-items: center;
  padding: 4px 15px;
  width: 100%;
  cursor: pointer;
}

.search-links-list li:hover {
  background: #c9c3c3b3;
}

.search-links-list span {
  margin-left: 14px;
}

.google[data-icon='simple-icons:google'] {
  color: rgb(185, 34, 34);
  height: 15px;
  width: 15px;
  margin-right: 2px;
}
.yahoo[data-icon='simple-icons:yahoo'] {
  color: #5f01d1;
  height: 17px;
  width: 17px;
}

.bing[data-icon='simple-icons:bing'] {
  color: #00887a;
  height: 17px;
  width: 17px;
}

.search-links-list .yahoo span {
  color: #000;
}
