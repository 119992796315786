.temperature-container {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
}

.temperature {
  display: flex;
  flex-direction: column;
}

.temperature-section {
  display: flex;
  padding: 8px 0 0 0;
  margin-bottom: 5px;
  max-width: 120px;
  max-height: 40px;
  line-height: 0;
}

.temperature i {
  font-size: 1rem;
  padding: 0 2px 0 0;
}

.temperature-degree {
  margin-top: 10px;
  font-size: 28px;
  font-weight: 500;
  padding: 0 0 5px 5px;
  cursor: pointer;
}

.temperature-degree .symbol {
  display: inline-block;
  font-size: 20px;
  transform: translateY(-7px);
}

.temperature-section span {
  cursor: pointer;
}

.temperature .temperature-city {
  align-self: flex-end;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
}

.temperature .temperature-description {
  text-transform: capitalize;
}
