* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
  color: #fff;
  overflow: hidden;
}

/*Preloader*/
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #015f5f;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  opacity: 1;
  transition: opacity 5s ease;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.2);
}

.container.bg-darken {
  background: rgba(0, 0, 0, 0.4);
}

.top-grid {
  position: absolute;
  top: 0;
  left: 0;
  height: 90vh;
  display: grid;
  justify-content: center;
  grid-template-columns: 20% 60% 20%;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}

.one {
  display: flex;
  flex-direction: column;
  padding: 30px 0 0 40px;
  visibility: visible;
}

.two {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  margin-bottom: 70px;
}

.three {
  position: relative;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  visibility: visible;
  padding: 40px 40px 0 0;
}

.four {
  position: absolute;
  bottom: 0;
  left: 0;
  visibility: visible;
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  padding: 30px 40px;
  margin: 0 auto;
}

@media (max-width: 700px) {
  #time {
    font-size: 6rem;
  }
  .one,
  .three,
  .four {
    visibility: hidden;
  }
}
