.forecast-card-container {
  background: #fdfdfd;
  color: #000;
  border-radius: 8px;
  width: 320px;
  max-width: 320px;
  max-height: 230px;
  padding: 0 20px;
  margin: 15px 0 50px 0;
  opacity: 0;
  transition: all 0.3s ease;
  font-size: 18px;
  pointer-events: none;
}

.forecast-card-container.show {
  opacity: 1;
  pointer-events: all;
}

/*CARD CURRENT INFO*/
.forecast-current {
  max-width: 300px;
}

.forecast-current-info {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 300px;
  padding: 15px 15px 10px 10px;
}

.forecast-current-title {
  width: 150px;
  text-transform: capitalize;
}

.forecast-current-city {
  font-size: 16px;
  line-height: 1;
}
.forecast-current-day {
  font-size: 12px;
}

.forecast-current-temp {
  width: 150px;
  display: flex;
  align-items: center;
  /*padding: 5px 0;*/
}

.forecast-current-info i {
  padding-right: 8px;
  font-size: 32px;
}

.forecast-current-toggle {
  margin-top: 8px;
  display: flex;
  font-size: 30px;
  line-height: 1;
  cursor: pointer;
}

.forecast-current-span {
  margin-top: 3px;
  font-size: 16px;
}

.forecast-current-description {
  font-size: 12px;
  color: #500250;
  text-transform: capitalize;
}

/*CARD FORECAST*/
.forecast-five {
  display: flex;
  border-top: 1px solid #b9b5b5;
  border-bottom: 1px solid #b9b5b5;
  font-size: 11px;
}

.forecast-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 6px;
  width: 100px;
  margin: 2px;
  padding: 2px 0px;
  text-transform: uppercase;
  transition: all 0.5s ease;
}

.forecast-item:hover {
  background: #dbd6d6;
}

.forecast-day {
  font-size: 9px;
}

.forecast-item i {
  font-size: 14px;
  padding: 2px 0;
}

.forecast-hilo {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.forecast-hi,
.forecast-lo {
  font-size: 9px;
  padding: 0 1.5px;
}

.forecast-hi,
.forecast-lo {
  cursor: pointer;
}

.forecast-hi-symbol,
.forecast-lo-symbol {
  font-size: 8px;
}

.forecast-lo {
  color: #868080;
}

/*CARD FOOTER*/
.forecast-links {
  display: flex;
  justify-content: flex-end;
  font-size: 11px;
  color: #500250;
}

.forecast-links a {
  display: block;
  text-decoration: none;
  color: #500250;
  padding: 10px 0;
}
