/*TODO TOGGLE*/
.todo-toggle {
  font-size: 14px;
  min-width: 20%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

/*TODO CARD*/
.todo-card-container {
  background: #fdfdfd;
  position: absolute;
  bottom: 20px;
  color: #000;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  min-width: 300px;
  max-height: 230px;
  padding: 15px 20px;
  opacity: 1;
  transition: all 0.7s ease;
  align-self: flex-end;
}

.todo-card-container.hide {
  opacity: 0;
}

/* TODO CARD HEADER*/
.todo-card-container h2 {
  align-self: flex-start;
  justify-self: flex-start;
  font-size: 16px;
}

.todo-card-container p {
  font-size: 14px;
  color: slategrey;
}

/*TODO CARD CENTER*/
.todo-card-center {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.todo-card-center.hide {
  display: none;
}

.todo-btn-center {
  align-self: center;
  border: 0;
  border-radius: 20px;
  width: 120px;
  margin-top: 16px;
  padding: 8px 18px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
}

/*TODOS LIST UL*/
.todos-container {
  display: none;
}

.todos-container.show {
  display: block;
}

.todos-list {
  min-width: 100%;
  padding: 0 20px 0 0;
  margin: 8px 0 8px 16px;
  list-style: none;
  transition: all 0.3s ease;
}

.todo-div {
  position: relative;
  display: flex;
}

.todo-item {
  margin: 0 8px;
  font-size: 14px;
  display: inline-block;
}

.todo-item.completed {
  text-decoration: line-through;
  opacity: 0.5;
}

.delete-btn,
.complete-btn {
  background: none;
  outline: none;
  border: none;
  color: #58585e;
  cursor: pointer;
  /*/padding: 1rem;*/
  font-size: 12px;
}

.complete-btn {
  position: absolute;
  left: -15px;
  margin-right: 5px;
  opacity: 0.2;
}

.complete-btn.show {
  opacity: 1;
}

.delete-btn {
  position: absolute;
  right: 10px;
}
.fa-trash,
.fa-check {
  pointer-events: none;
}

/*TODO CARD FORM*/
.todo-form {
  align-self: flex-start;
  min-height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.todo-input {
  padding: 0.25rem;
  font-size: 14px;
  border: none;
  outline: none;
  background: transparent;
}

.todo-btn {
  color: #8a8280;
  background: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.todo-input::placeholder {
  font-family: 'Poppins', 'sans-serif';
  font-size: 14px;
  color: #949393;
  opacity: 0.6;
}
